<template>
  <div class="appmain">
    <div class="titles">
      Privacy Policy
    </div>
    <div>
      <div class="content">
        <div class="kongge">SMARTGEN (ZHENGZHOU) TECHNOLOGY CO., LTD (hereinafter referred to as “SmartGen”, “We”, “Our”) attaches great importance to protecting the personal information of users (including private users who visit SmartGen website, business partners of SmartGen and applicants who apply for position online, hereinafter referred to as “You”), and strictly abide by the provision of The Cybersecurity Law of the People’s Republic of China and Personal Information Protection Law of the People’s Republic of China and other relevant laws and regulations. Please read the following for more information about our Privacy Policy (“This Policy”).</div>
        <div class="kongge">The policy applies to SmartGen website or product or service.</div>
        <div class="kongge">The policy describes how SmartGen handles your personal information, but it may not cover all possible information processing scenarios. SmartGen may inform you of the information/data collection of specific product or service through supplementary policies or the notice provided before collection.</div>
        <div class="biaoti">1.How we collect and use your personal information</div>
        <div class="kongge">Personal information refers to the information that can be used alone or together with other data to identify natural persons. When you use our website, products, service or interact with us, you directly provide us with such information, like creating a SmartGen account or contacting us for support. We may also obtain information by recording how you interact with our website or service. Where permitted by law, we may also obtain information from public and commercial third-party sources, such as purchasing statistical data from other companies to support our service. The personal information we collect includes: name, company name, industry, country, email address, phone number, login information (account and password), etc., which depends on the way you interact with SmartGen, such as the website you visit or product and service you use. We also collect the information you provide to us and the contents of messages you send us, such as the problem feedback you provide or technical support you seek.</div>
        <div class="kongge">You may need to provide personal information before using SmartGen products or service. In some cases, you can choose not to disclose your personal information to SmartGen. However, we cannot provide you with corresponding service if you do not provide some information to SmartGen.</div>
        <div>We may use your personal information for the following purposes:</div>
        <div class="kongge">Create account</div>
        <div class="kongge">Contact you with your consent. Send you information about the product and service you may be interested in. Invite you to participate in SmartGen activities (including promotional activities), market research or satisfaction survey. Send you marketing information. If you don’t want to receive these information, you can choose to exit at any time.</div>
        <div class="kongge">Provide you with customized user experience and content.</div>
        <div class="kongge">Communicate or cooperate with suppliers and business partners.</div>
        <div class="kongge">Comply with and implement applicable legal requirements, industry standard and out policies.</div>
        <div class="kongge">SmartGen will process your personal information in accordance with the requirements of applicable laws on the basis of appropriate laws, including:</div>
        <div class="kongge">Process your personal information with your consent.</div>
        <div class="kongge">When we use your personal information to contact you, conduct marketing or do market survey and improve our product and service, we will deal with it based on the legitimate interests of SmartGen or third-party.</div>
        <div class="kongge">Process your personal information as needed to comply with and fulfill legal obligations.</div>
        <div class="biaoti">2.Cookie and similar technologies</div>
        <div class="kongge">To ensure the normal operation of our website, we may sometimes place a small piece of data called cookie on your computer or mobile devices. Cookie is the text file stored on computers or mobile devices by web servers. The contents of cookie can only be retrieved or read by the server that created the cookie. </div>
        <div class="kongge">Text in the cookie usually consists of identifiers, site names, some numbers and characters. Cookie is unique to the browser or mobile apps you use. It enables website to store data, such as your preferences or goods in shopping carts. </div>
        <div class="kongge">Like many other websites or Internet service providers, SmartGen uses cookies to improve the user experience. The session cookie will be deleted after each visit, while the persistent cookie will remain unchanged after multiple visits. Cookies allow websites to remember your settings, such as language, font size, or other browser preferences on your computer or mobile device.</div>
        <div class="kongge">SmartGen will not use cookies for any purpose not specified in this policy. You can manage or delete cookies according to your preferences. For more information, please visit AboutCookies.org. You can clear all cookies stored on your computer, and most web browsers provide the option to block cookies. </div>
        <div>However, to do so, you must change the user settings every time you visit our website. Learn how to manage your browser's cookie settings here:</div>
        <div class="kongge">Internet Explorer</div>
        <div class="kongge">Google Chrome</div>
        <div class="kongge">Mozilla Firefox</div>
        <div class="kongge">Safari</div>
        <div class="kongge">Opera</div>
        <div class="biaoti">3.How we disclose personal information</div>
        <div class="kongge">When the service is provided by a partner authorized by SmartGen, SmartGen will share your personal information with other partners in accordance with the provisions of this policy. For example, when you purchase products from SmartGen or SmartGen licensees, we must share your personal information with logistics providers to arrange shipments or partners to provide services.</div>
        <div class="biaoti">4.How to access and control your personal information</div>
        <div class="kongge">You are responsible for ensuring that all personal information submitted to SmartGen is correct. SmartGen is committed to maintaining the accuracy and integrity of personal information and maintaining the latest status of information.</div>
        <div class="kongge">You are responsible for ensuring that all personal information submitted to SmartGen is correct. SmartGen is committed to maintaining the accuracy and integrity of personal information and maintaining the latest status of information.</div>
        <div>To the extent required by applicable law:</div>
        <div class="kongge">a. You may have access to some of the personal information we maintain about you.</div>
        <div class="kongge">b. We are required to update or correct the inaccuracies in this information.</div>
        <div class="kongge">c. Oppose or restrict our use of your personal information, and require us to delete your personal information from our database. To exercise these rights, please contact us to feedback your needs. For security reasons, your written request may be required. If we have reasonable reasons to believe that the request is fraudulent, infeasible or may endanger the privacy of others, we may refuse the request.</div>
        <div class="kongge">If the applicable law permits, you have the right to withdraw your consent at any time when SmartGen processes your personal information according to your consent. However, withdrawal does not affect the legality and validity of how we deal with your personal information based on your consent before withdrawal. It also does not affect any information processing for reasons other than your consent.</div>
        <div class="biaoti">5.How do we protect and retain your personal information</div>
        <div class="kongge">The security of your personal information is very important to us. We take certain technical measures to protect your personal information from unauthorized access, disclosure, use, modification, damage or loss. We also provide security and privacy protection training for employees to improve their awareness of personal information protection. SmartGen is committed to protecting your personal information.</div>
        <div class="biaoti">6.How do we deal with children's personal information</div>
        <div class="kongge">Our website, products and services are mainly for adults. Without the consent of parents or guardians, children are not allowed to create SmartGen account. If a child's personal information is collected with the prior consent of his or her parents, we will only use or disclose such information as permitted by law, with the explicit consent of the child's parents or guardians, or when necessary to protect the child. If we accidentally collect the child's personal information without the prior verification and consent of the child's parents, we will try to delete the information as soon as possible.</div>
        <div class="biaoti">7.Third party providers and their services</div>
        <div class="kongge">To ensure a good user experience, you may receive content or web links from third parties (“third parties”) other than SmartGen and its partners. SmartGen has no right to control such third parties, but you can choose whether to use links, view content and/or access products or services provided by third parties.</div>
        <div class="kongge">SmartGen cannot control the privacy practices and information protection policies of third parties that are not bound by this policy. When you submit personal information to such third parties, please read and refer to the privacy protection policy of the third party.</div>
        <div class="biaoti">8.Update of this policy</div>
        <div class="kongge">SmartGen reserves the right to update or change this policy at any time. We will post the latest privacy policy on this page to make any changes. If there is a major change in privacy policy, we may notify you through different channels, such as posting a notice on our website or sending you a direct notice.</div>
        <div class="biaoti">9.How to contact us</div>
        <div class="kongge">If you have any questions, comments or suggestions about this privacy policy, you can contact us through the following ways:</div>
        <div class="kongge">Tel: 0086-371-67988888/67981000</div>
        <div class="kongge">Email: sales@smartgen.cn</div>
        <div class="kongge">We will reply and deal with the information we receive in time.</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background:#ffffff')
  },
  deactivated() {
    document.querySelector('body').removeAttribute('style')
  }
}
</script>
<style scoped>
.titles {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  margin-top: 20px;
  margin-bottom: 30px;
}
.content {
  font-size: 16px;
  color: #333333;
  line-height: 36px;
}
.kongge {
  text-indent: 40px;
}
.biaoti {
  font-size: 20px;
  font-weight: bold;
  color: #333333;
}
</style>